import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './PublicLayout.module.css';

const PublicLayout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  return (
    <div className={`${styles.outerContainer} ${styles.outerbg}`}>
      <Outlet />
    </div>
  );
};

export default PublicLayout;
