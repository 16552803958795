import React from 'react';

interface DirectionIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const DirectionIcon: React.FC<DirectionIconProps> = ({
  width = 18,
  height = 18,
  fill = '#191C21',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1892_30646)">
      <path
        d="M17.4371 7.64207L10.3579 0.562887C9.60741 -0.187559 8.39271 -0.187699 7.64209 0.562887L0.5629 7.64207C-0.187616 8.39256 -0.187651 9.60743 0.5629 10.3579L7.64209 17.4371C8.3926 18.1876 9.6073 18.1877 10.3579 17.4371L17.4371 10.3579C18.1876 9.60746 18.1877 8.39259 17.4371 7.64207ZM16.6417 9.56246L9.56248 16.6417C9.25157 16.9525 8.74847 16.9526 8.43753 16.6417L1.35834 9.56246C1.04751 9.25163 1.04747 8.74846 1.35834 8.43752L8.43753 1.35837C8.74844 1.04749 9.25154 1.04746 9.56248 1.35837L16.6417 8.43755C16.6417 8.44747 16.8746 8.62992 16.8746 9.00003C16.8746 9.2125 16.7919 9.41221 16.6417 9.56246Z"
        fill={fill}
      />
      <path
        d="M13.1256 7.25354C13.0815 7.18681 13.2174 7.33144 11.1486 5.23035C10.9307 5.00899 10.5745 5.00621 10.3532 5.2242C10.1318 5.44216 10.1291 5.79828 10.347 6.01961L11.343 7.03115H5.90622C5.59556 7.03115 5.34375 7.283 5.34375 7.59362V11.2497C5.34375 11.5603 5.59556 11.8122 5.90622 11.8122C6.21688 11.8122 6.4687 11.5603 6.4687 11.2497V8.1561H11.2824L10.2864 9.16763C9.9354 9.52414 10.1927 10.1248 10.6872 10.1248C10.8326 10.1248 10.9779 10.0687 11.088 9.95693L12.9339 8.08217C13.2472 7.90347 13.2884 7.49997 13.1256 7.25354Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1892_30646">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DirectionIcon;
