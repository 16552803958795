import React from "react";

interface InfoIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const InfoIcon: React.FC<InfoIconProps> = ({
  width = 20,
  height = 20,
  fillColor = "black",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1542 17.8417L13.8748 18.984C13.0364 19.3149 12.3668 19.5668 11.8681 19.7399C11.3689 19.9136 10.7888 20 10.128 20C9.11305 20 8.32364 19.7514 7.76062 19.2576C7.1976 18.7618 6.916 18.1335 6.916 17.3713C6.916 17.0763 6.93643 16.7728 6.97891 16.4636C7.02175 16.154 7.08987 15.8054 7.18308 15.4157L8.23079 11.7074C8.32399 11.3523 8.40322 11.0159 8.46668 10.6977C8.53103 10.3815 8.56204 10.0906 8.56204 9.82873C8.56204 9.35515 8.46399 9.0239 8.26879 8.83766C8.07358 8.65196 7.70111 8.55768 7.14812 8.55768C6.87728 8.55768 6.59891 8.60106 6.31516 8.68494C6.03015 8.76919 5.78655 8.85057 5.58203 8.92621L5.8622 7.78297C6.54854 7.50352 7.20459 7.26422 7.83214 7.06561C8.45969 6.86647 9.05264 6.76681 9.61351 6.76681C10.6214 6.76681 11.3992 7.01041 11.9453 7.4976C12.4915 7.98516 12.7645 8.61719 12.7645 9.39602C12.7645 9.55717 12.7466 9.8411 12.7081 10.2469C12.6704 10.6536 12.6003 11.0263 12.4982 11.3652L11.4549 15.0587C11.3694 15.3553 11.2925 15.6945 11.2257 16.0761C11.1567 16.4552 11.1237 16.7448 11.1237 16.9392C11.1237 17.4296 11.233 17.7644 11.4522 17.9424C11.6731 18.1204 12.0534 18.209 12.5937 18.209C12.8473 18.209 13.1359 18.164 13.4566 18.0756C13.7764 17.9872 14.0096 17.9096 14.1542 17.8417ZM14.4188 2.33561C14.4188 2.97911 14.1763 3.52868 13.6889 3.98057C13.2028 4.43407 12.617 4.661 11.9317 4.661C11.2443 4.661 10.6571 4.43407 10.1654 3.98057C9.67463 3.52851 9.4287 2.97911 9.4287 2.33561C9.4287 1.69336 9.67463 1.14289 10.1654 0.685267C10.6562 0.228362 11.2445 0 11.9317 0C12.6168 0 13.2028 0.2289 13.6889 0.685267C14.1766 1.14289 14.4188 1.69354 14.4188 2.33561Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default InfoIcon;
