export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';

export const CLEAR_APPOINTMENT_STATE = 'CLEAR_APPOINTMENT_STATE';

export const GET_APPOINTMENTS_REQUEST = 'GET_APPOINTMENTS_REQUEST';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAILURE = 'GET_APPOINTMENTS_FAILURE';

export const GET_APPOINTMENT_DETAILS_REQUEST = 'GET_APPOINTMENT_DETAILS_REQUEST';
export const GET_APPOINTMENT_DETAILS_SUCCESS = 'GET_APPOINTMENT_DETAILS_SUCCESS';
export const GET_APPOINTMENT_DETAILS_FAILURE = 'GET_APPOINTMENT_DETAILS_FAILURE';

export const SET_APPOINTMENT_CHAT_SESSION_REQUEST = 'sET_APPOINTMENT_CHAT_SESSION_REQUEST';
export const SET_APPOINTMENT_CHAT_SESSION_SUCCESS = 'SET_APPOINTMENT_CHAT_SESSION_SUCCESS';
export const SET_APPOINTMENT_CHAT_SESSION_FAILURE = 'SET_APPOINTMENT_CHAT_SESSION_FAILURE';

export const OPEN_AMAZON_DOOR_REQUEST = "OPEN_AMAZON_DOOR_REQUEST";
export const OPEN_AMAZON_DOOR_SUCCESS = "OPEN_AMAZON_DOOR_SUCCESS";
export const OPEN_AMAZON_DOOR_FAILURE = "OPEN_AMAZON_DOOR_FAILURE";

export const RESET_AMAZON_DOOR = 'RESET_AMAZON_DOOR';