import React from "react";

interface CallIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const CallIcon: React.FC<CallIconProps> = ({
  width = 22,
  height = 22,
  fillColor = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1836_32619)">
        <path
          d="M18.1295 9.29605C18.155 6.30785 15.6099 3.56845 12.4562 3.18964C12.3934 3.18221 12.3251 3.17089 12.2528 3.15891C12.0966 3.13304 11.9352 3.10645 11.7723 3.10645C11.1259 3.10645 10.9531 3.56049 10.9076 3.83128C10.8634 4.09457 10.9056 4.31567 11.0328 4.48871C11.2469 4.77978 11.6235 4.83145 11.926 4.87285C12.0147 4.8851 12.0984 4.89648 12.1684 4.91222C15.0019 5.54536 15.956 6.54079 16.4223 9.35022C16.4337 9.41888 16.4388 9.50346 16.4443 9.59317C16.4646 9.929 16.507 10.6277 17.2582 10.6277H17.2582C17.3208 10.6277 17.388 10.6223 17.4579 10.6116C18.1574 10.5053 18.1355 9.86667 18.125 9.5598C18.122 9.47338 18.1192 9.39162 18.1265 9.33726C18.1285 9.32356 18.1295 9.30981 18.1295 9.29605Z"
          fill={fillColor}
        />
        <path
          d="M11.5899 2.09319C11.6738 2.09924 11.7532 2.10504 11.8194 2.11517C16.4723 2.83073 18.6121 5.03525 19.2051 9.72457C19.2152 9.80428 19.2168 9.9015 19.2184 10.0044C19.2243 10.3708 19.2365 11.133 20.055 11.1488L20.0804 11.149C20.3371 11.149 20.5413 11.0716 20.6874 10.9188C20.9422 10.6523 20.9244 10.2563 20.91 9.93816C20.9066 9.8601 20.9032 9.78657 20.904 9.7222C20.9632 4.92625 16.8118 0.5773 12.0201 0.415574C12.0003 0.414916 11.9813 0.415969 11.9621 0.418668C11.9527 0.42005 11.9353 0.421761 11.9054 0.421761C11.8576 0.421761 11.7988 0.417615 11.7365 0.413402C11.6611 0.408268 11.5757 0.402344 11.4891 0.402344C10.726 0.402344 10.5809 0.94472 10.5624 1.26804C10.5196 2.01526 11.2424 2.06785 11.5899 2.09319Z"
          fill={fillColor}
        />
        <path
          d="M18.9802 15.7851C18.8813 15.7095 18.779 15.6313 18.6832 15.5542C18.1752 15.1454 17.6347 14.7685 17.112 14.4041C17.0035 14.3285 16.8951 14.2529 16.787 14.177C16.1174 13.7067 15.5155 13.4782 14.9468 13.4782C14.1808 13.4782 13.513 13.9014 12.9618 14.736C12.7176 15.106 12.4213 15.2859 12.0562 15.2859C11.8403 15.2859 11.595 15.2243 11.3271 15.1027C9.166 14.1227 7.6228 12.6201 6.74032 10.6367C6.31372 9.67802 6.45202 9.05139 7.20279 8.5414C7.62912 8.25205 8.42267 7.71336 8.36646 6.68179C8.30281 5.51035 5.7181 1.98563 4.62907 1.58529C4.16798 1.41574 3.68353 1.41416 3.18657 1.58135C1.93463 2.00221 1.03622 2.74133 0.588232 3.71866C0.155318 4.66302 0.174867 5.77186 0.644773 6.92533C2.00321 10.2603 3.91312 13.1679 6.32162 15.5673C8.67912 17.9162 11.5767 19.8401 14.9338 21.2857C15.2363 21.4159 15.5537 21.4869 15.7855 21.5387C15.8645 21.5565 15.9327 21.5716 15.9824 21.5852C16.0097 21.5927 16.0379 21.5967 16.0661 21.5969L16.0926 21.5971C16.0926 21.5971 16.0926 21.5971 16.0928 21.5971C17.6717 21.5971 19.5676 20.1542 20.1498 18.5093C20.66 17.069 19.7286 16.3571 18.9802 15.7851Z"
          fill={fillColor}
        />
        <path
          d="M12.2875 5.90292C12.0172 5.90977 11.4546 5.92372 11.2572 6.4967C11.1648 6.76447 11.1759 6.99695 11.2901 7.18771C11.4577 7.46758 11.7788 7.55335 12.0708 7.60041C13.13 7.77037 13.6739 8.35605 13.7827 9.44344C13.8333 9.95047 14.1747 10.3045 14.6131 10.3045C14.6455 10.3045 14.6786 10.3026 14.7116 10.2986C15.2389 10.2359 15.4944 9.84851 15.4713 9.14737C15.4798 8.41562 15.0968 7.58488 14.4455 6.92317C13.7919 6.25941 13.0041 5.88561 12.2875 5.90292Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1836_32619">
          <rect
            width="21.1948"
            height="21.1948"
            fill="white"
            transform="translate(0 0.402344)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CallIcon;
