import React, { useEffect, useRef, useState } from 'react';
import {
    Connection,
    Coordinate,
    Door,
    Floor,
    MapData,
    MapObject,
    MapView,
    PointOfInterest,
    Space,
    MappedinMapLibreOverlay,
    getMapData,
    show3dMap
} from '@mappedin/mappedin-js';
import Select, { SelectRenderer } from 'react-dropdown-select';

import SearchIcon from '../icons/SearchIcon';
import DirectionIcon from '../icons/DirectionIcon';
import OutlinedCloseIcon from '../icons/OutlinedCloseIcon';
import ManWithParachute from '../../images/icons/parachute.svg';

// mapped in custom icons
import ClubHouse from '../../images/mapped-in/clubhouse.svg';
import BusinessCenter from '../../images/mapped-in/business-center.svg';
import Gym from '../../images/mapped-in/gym.svg';
import DogPark from '../../images/mapped-in/dog-park.svg';
import EVChargingStation from '../../images/mapped-in/electric-vehicle-charging-station.svg';
import LaundryRoom from '../../images/mapped-in/laundry-room.svg';
import RentalSpace from '../../images/mapped-in/storage-space-rentals.svg';
import PackageLockers from '../../images/mapped-in/package-locker.svg';
import Pool from '../../images/mapped-in/pool.svg';
import PetWashingCenter from '../../images/mapped-in/pet-washing-station.svg';
import PicnicArea from '../../images/mapped-in/picnic-area.svg';
import PlayGround from '../../images/mapped-in/playground.svg';
import GardenIcon from '../../images/mapped-in/garden.svg';
import PineappleSorry from '../../images/pineapple-sorry.png';

import ZoomResetIcon from '../icons/ZoomResetIcon';
import ZoomOutIcon from '../icons/ZoomOutIcon';
import ZoomInIcon from '../icons/ZoomInIcon';
import InfoIcon from '../icons/InfoIcon';
import CallIcon from '../icons/CallIcon';

import '@mappedin/mappedin-js/lib/index.css';
import './Mappit.css';
import Loader from '../common/loader/Loader';
import { AmenitiesObject } from '../../redux/appointment/actions/appointment.action';

export const keywords = ['locate', 'show', 'navigate', 'take'];
interface LocationProps {
    label: string;
    value: string;
    type: string;
    center?: Coordinate;
    links?: string;
    images?: string[];
    floor: Floor;
}

interface FloorProps {
    label: string;
    value: string;
    floorDetail: Floor;
}

interface MappedInProps {
    mapId: string;
    floorId: string | null;
    agentNumber: string | null;
    rentalLink: string | null;
    amenitiesData?: AmenitiesObject[] | null;
    appointmentId: string;
}

export const icons = [
    { label: 'playground', icon: PlayGround },
    { label: 'clubhouse', icon: ClubHouse },
    { label: 'business center', icon: BusinessCenter },
    { label: 'gym', icon: Gym },
    { label: 'fitness center', icon: Gym },
    { label: 'dog park', icon: DogPark },
    { label: 'pet park', icon: DogPark },
    { label: 'electric vehicle charging station', icon: EVChargingStation },
    { label: 'laundry room', icon: LaundryRoom },
    { label: 'laundry', icon: LaundryRoom },
    { label: 'storage space rentals', icon: RentalSpace },
    { label: 'package locker', icon: PackageLockers },
    { label: 'pool', icon: Pool },
    { label: 'swimming pool', icon: Pool },
    { label: 'pet washing station', icon: PetWashingCenter },
    { label: 'picnic area', icon: PicnicArea },
    { label: 'garden', icon: GardenIcon },
];

const MappedIn = ({ mapId, floorId, agentNumber, rentalLink, amenitiesData, appointmentId }: MappedInProps) => {

    const hasMounted = useRef(false);
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [mappedInData, setMappedInData] = useState<MapData | null>(null);
    const [mappedInView, setMappedInView] = useState<MapView | null>(null);
    const [error, setError] = useState(false);

    const [showSelectorDropDown, setShowSelectorDropDown] = useState(false);
    const [showDestinationDropDown, setShowDestinationDropDown] = useState(false);
    const [showDestinationSelector, setShowDestinationSelector] = useState(false);

    const [locationList, setLocationList] = useState<LocationProps[]>([]);
    const [selectedSource, setSelectedSource] = useState<LocationProps | null>(null);
    const [selectedDestination, setSelectedDestination] = useState<LocationProps | null>(null);
    const [enteranceList, setEnteranceList] = useState<Coordinate[] | null>(null);
    const [floorData, setFloorData] = useState<FloorProps[] | null>(null);
    const [selectedFloor, setSelectedFloor] = useState<FloorProps | null>(null);
    const [selectedPin, setSelectedPin] = useState(false);

    const [spaceList, setSpaceList] = useState<LocationProps[]>([]);
    const [checkPointList, setCheckPointList] = useState<LocationProps[]>([]);
    const [selectedTab, setSelectedTab] = useState('rooms');
    const [selectedDestinationTab, setSelectedDestinationTab] = useState('Dropped Pin');

    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [isVisible, setIsVisible] = useState(false);
    const [initialCameraState, setInitialCameraState] = useState({
        zoomLevel: 0,
        pitch: 0,
        bearing: 0,
    });
    const [initialCenterState, setInitialCenterState]: any = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const [isDynamicIframeVisible, setIsDynamicIframeVisible] = useState(false);

    const filteredLocationData = () => {
        const filterLocations = locationList?.filter((option) => option.label !== selectedSource?.label) || [];
        return filterLocations;
    };

    const filteredSourceData = () => {
        const filterLocations = locationList?.filter((option) => option.label !== selectedDestination?.label) || [];
        return filterLocations;
    };

    useEffect(() => {
        if (selectedSource?.label && mappedInView && selectedDestination === null && selectedDestinationTab !== 'Nearest Entrance') {
            filteredLocationData();
            let firstSpace: Coordinate | undefined = selectedSource?.center;
            if (firstSpace) {
                mappedInView.Camera.animateTo(
                    {
                        bearing: 30,
                        pitch: 80,
                        zoomLevel: 20,
                        center: selectedSource.center,
                    },
                    { duration: 4000, easing: 'ease-in-out' },
                );
            }
        } else if (selectedSource?.center && selectedDestinationTab === 'Nearest Entrance') {
            findNearestEntrance(selectedSource.center);
        } else if (selectedSource?.label && selectedDestination?.label) {
            filteredLocationData();
            let firstSpace: Coordinate | undefined = selectedSource?.center;
            if (firstSpace) {
                drawDirections(firstSpace, true)
            }
        }
    }, [selectedSource]);

    useEffect(() => {
        clearStats();
    }, [selectedDestinationTab]);

    const findNearestWithPrecision = (clickedLat: number, clickedLon: number) => {
        const maxPrecision = 15; // Maximum precision to consider
        let currentPrecision = 4; // Start at 4 decimal points

        const toFixedPrecision = (value: number, decimals: number) => parseFloat(value.toString()).toFixed(decimals);

        let matches = [];
        do {
            const clickedLatRounded = toFixedPrecision(clickedLat, currentPrecision);
            const clickedLonRounded = toFixedPrecision(clickedLon, currentPrecision);

            matches = amenitiesData ? amenitiesData.filter((amenity: AmenitiesObject) => {
                const amenityLatRounded = toFixedPrecision(amenity.latitude, currentPrecision);
                const amenityLonRounded = toFixedPrecision(amenity.longitude, currentPrecision);
                return clickedLatRounded === amenityLatRounded && clickedLonRounded === amenityLonRounded;
            }) : [];

            if (matches.length <= 1) break; // Stop if only one or no match is found
            currentPrecision++; // Increase precision if there are multiple matches
        } while (currentPrecision <= maxPrecision);

        return matches.length === 1 ? matches[0] : null; // Return the single match or null
    };

    const closeIcon = document.querySelector(".close-icon");
    closeIcon?.addEventListener("click", () => {
        // Remove the iframe container on click
        resetMarkers();
        setActiveIndex(null);
        setIsModalOpen(false);
    });

    const getMapClick = async (event: any) => {
        const { coordinate } = event;
        const nearestAmenity = findNearestWithPrecision(coordinate.latitude, coordinate.longitude);

        if (nearestAmenity) {
            mappedInView?.Markers.removeAll();

            const newIFrame = `
            <div style="position: relative; width:100%">
                <div class="close-icon" style="opacity:0; width:24px; height:24px; z-index:5; position: absolute; top:20px; right:20px">
                    
                </div>

                <iframe 
                    src="${process.env.REACT_APP_WEB_ADDRESS}map-details/${appointmentId}/${coordinate?.latitude}/${coordinate.longitude}" 
                    style="border: 0; height: 390px; width: 320px; max-width:calc(100vw - 32px); border-radius:12px"
                />
            </div>
            `;
            await mappedInView?.Markers?.add(coordinate as Coordinate, newIFrame, {
                rank: 'high',
                interactive: false,
                dynamicResize: true,
                layer: '',
                anchor: 'center',
            },);

            setActiveIndex(0)
            setTimeout(() => {
                setIsModalOpen(true);
            }, 100);
        } else {
            if (document.querySelector(".marker-popup")) {
                resetMarkers();
            }
            setActiveIndex(null);
            setIsModalOpen(false);
        }
    }

    useEffect(() => {
        const handleMapClick = async (event: any) => {
            if (isVisible && selectedSource && mappedInData) {
                let secondSpace: Coordinate = event.coordinate;
                if (!secondSpace.floorId && mappedInView) {
                    mappedInView.Markers.add(event.coordinate, '', {
                        interactive: true,
                        anchor: 'center',
                    });
                    drawDirections({ ...event.coordinate, floorId: selectedFloor?.floorDetail.id } as Coordinate);
                    setSelectedPin(true);
                } else {
                    drawDirections(secondSpace);
                    setIsVisible(false);
                    setSelectedPin(true);
                }
            }
        };
        isVisible && mappedInView?.on('click', handleMapClick);

        // !isVisible && mappedInView?.on('click', getMapClick)

        !isVisible && mappedInView?.on('click', getMapClick)
        return () => {
            mappedInView?.off('click', handleMapClick);
        };
    }, [isVisible, mappedInView, selectedSource]);


    useEffect(() => {
        if (selectedDestination?.label) {
            filteredSourceData();
        }
    }, [selectedDestination]);

    useEffect(() => {
        if (mapContainerRef && mapContainerRef.current && selectedDestinationTab === 'Dropped Pin' && showDestinationSelector && !selectedPin) {

            mapContainerRef.current.style.cursor = 'drop';
            const canvas = mapContainerRef.current.querySelector('canvas');

            if (canvas) {
                const handleMouseMove = (e: MouseEvent) => {
                    const mapBounds = mapContainerRef.current?.getBoundingClientRect();
                    if (mapBounds) {
                        const x = e.clientX - mapBounds.left;
                        const y = e.clientY - mapBounds.top;

                        if (mapBounds) {
                            const x = e.clientX - mapBounds.left;
                            const y = e.clientY - mapBounds.top;

                            if (
                                x >= 24 &&
                                y >= 0 &&
                                x <= mapBounds.width - 24 &&
                                y <= mapBounds.height
                            ) {
                                setCursorPosition({ x, y });
                                setIsVisible(true);
                            } else {
                                setIsVisible(false);
                            }
                        }
                    }
                };

                const handleMouseLeave = () => {
                    setIsVisible(false);
                };

                mapContainerRef.current.addEventListener('mousemove', handleMouseMove);
                mapContainerRef.current.addEventListener('mouseleave', handleMouseLeave);

                return () => {
                    mapContainerRef.current?.removeEventListener('mousemove', handleMouseMove);
                    mapContainerRef.current?.removeEventListener('mouseleave', handleMouseLeave);
                };
            }
        } else {
            setIsVisible(false);
        }
    }, [selectedDestinationTab, mapContainerRef, showDestinationSelector, selectedPin]);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            initMap();
        }
    }, []);

    const clearDirection = () => mappedInView?.Navigation?.clear();

    const clearStats = () => {
        clearDirection();
        setIsVisible(false);
    };

    const calculateDistance = (coord1: Coordinate, coord2: Coordinate) => {
        const R = 6371e3; // Radius of the Earth in meters
        const toRad = (x: number): number => (x * Math.PI) / 180;

        const dLat = toRad(coord2.latitude - coord1.latitude);
        const dLon = toRad(coord2.longitude - coord1.longitude);
        const lat1 = toRad(coord1.latitude);
        const lat2 = toRad(coord2.latitude);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    };

    const drawDirections = async (secondSpace: Coordinate, isSource?: boolean) => {
        let firstSpace = isSource ? selectedDestination?.center : selectedSource?.center;
        isSource = isSource ?? false;
        if (firstSpace && secondSpace && !isSource) {
            if (mappedInView) {
                const directions = mappedInView.getDirections(firstSpace, secondSpace);
                if (directions) {
                    clearDirection();
                    mappedInView?.Navigation.draw(directions);
                    const midLat = (firstSpace.latitude + secondSpace.latitude) / 2;
                    const midLong = (firstSpace.longitude + secondSpace.longitude) / 2;
                    const centerCoordinates = { latitude: midLat, longitude: midLong };

                    // Calculate the distance between the two points
                    const distance = calculateDistance(firstSpace, secondSpace);

                    // Adjust the zoom level based on the distance (example logic)
                    let zoomLevel: number;

                    if (distance > 1000) {
                        zoomLevel = 12; // Beyond 1000 meters, set a far zoom level
                    } else if (distance > 900) {
                        zoomLevel = 13; // 901-1000 meters
                    } else if (distance > 800) {
                        zoomLevel = 13.5; // 801-900 meters
                    } else if (distance > 600) {
                        zoomLevel = 16; // 701-800 meters
                    } else if (distance > 400) {
                        zoomLevel = 16.5; // 401-600 meters
                    } else if (distance > 300) {
                        zoomLevel = 17; // 301-400 meters
                    } else if (distance > 200) {
                        zoomLevel = 17; // 201-300 meters
                    } else if (distance > 100) {
                        zoomLevel = 17.5; // 101-200 meters
                    } else {
                        zoomLevel = 20;
                    }

                    if (window?.innerWidth < 767) {
                        zoomLevel -= 2;
                    }

                    // Animate the camera to the center point with appropriate zoom, pitch, and bearing
                    mappedInView.Camera.animateTo(
                        {
                            bearing: 30,
                            pitch: 150,
                            zoomLevel: zoomLevel, // Calculated zoom level
                            center: { latitude: centerCoordinates.latitude, longitude: centerCoordinates.longitude } as Coordinate // Midpoint of the two spaces
                        },
                        { duration: 4000, easing: 'ease-in-out' }
                    );
                    setIsVisible(false);
                }
            }
        }
        if (isSource && firstSpace && secondSpace && mappedInView) {
            const directions = mappedInView.getDirections(secondSpace, firstSpace);
            if (directions) {
                clearDirection();
                mappedInView?.Navigation.draw(directions);
                const midLat = (firstSpace.latitude + secondSpace.latitude) / 2;
                const midLong = (firstSpace.longitude + secondSpace.longitude) / 2;
                const centerCoordinates = { latitude: midLat, longitude: midLong };

                // Calculate the distance between the two points
                const distance = calculateDistance(secondSpace, firstSpace);

                // Adjust the zoom level based on the distance (example logic)
                let zoomLevel: number;

                if (distance > 1000) {
                    zoomLevel = 12; // Beyond 1000 meters, set a far zoom level
                } else if (distance > 900) {
                    zoomLevel = 13; // 901-1000 meters
                } else if (distance > 800) {
                    zoomLevel = 13.5; // 801-900 meters
                } else if (distance > 600) {
                    zoomLevel = 16; // 701-800 meters
                } else if (distance > 400) {
                    zoomLevel = 16.5; // 401-600 meters
                } else if (distance > 300) {
                    zoomLevel = 17; // 301-400 meters
                } else if (distance > 200) {
                    zoomLevel = 17; // 201-300 meters
                } else if (distance > 100) {
                    zoomLevel = 17.5; // 101-200 meters
                } else {
                    zoomLevel = 20;
                }

                if (window?.innerWidth < 767) {
                    zoomLevel -= 2;
                }

                // Animate the camera to the center point with appropriate zoom, pitch, and bearing
                mappedInView.Camera.animateTo(
                    {
                        bearing: 30,
                        pitch: 150,
                        zoomLevel: zoomLevel, // Calculated zoom level
                        center: { latitude: centerCoordinates.latitude, longitude: centerCoordinates.longitude } as Coordinate // Midpoint of the two spaces
                    },
                    { duration: 4000, easing: 'ease-in-out' }
                );
                setIsVisible(false);
            }
        }
    };

    const initMap = async () => {
        try {
            setIsLoading(true);
            const mapData = await getMapData({
                key: process.env.REACT_APP_MAPPEDIN_KEY || "",
                secret: process.env.REACT_APP_MAPPEDIN_SECRET || '',
                mapId: mapId,
            });

            if (mapData) {
                const mapView = await show3dMap(document.getElementById('mappedin-map') as HTMLDivElement, mapData);
                setIsLoading(false);
                setError(false);

                if (mapData && mapView) {
                    const { zoomLevel, pitch, bearing } = mapView.Camera;
                    setInitialCenterState(mapView.Camera.center);
                    setInitialCameraState({ zoomLevel, pitch, bearing });
                }

                const getAllLocations = async () => {
                    const locations: LocationProps[] = [];
                    const spaceData: LocationProps[] = [];
                    const checkPointData: LocationProps[] = [];
                    const doorData: Coordinate[] = [];

                    if (mapView !== null && mapData) {
                        mapData.getByType('object').forEach((value: MapObject) => {
                            if (mapView && value.name) {
                                if (value.name) {
                                    mapView.updateState(value, {
                                        interactive: true,
                                        hoverColor: '#51AB86',
                                    });
                                } else {
                                    mapView.updateState(value, {
                                        interactive: true,
                                        hoverColor: '#51AB86',
                                    });
                                }

                                const matchingIcon = icons.find((icon) => icon.label.toLowerCase() === value.name.toLowerCase());
                                const markerIcon = matchingIcon ? matchingIcon.icon : null;
                                if (markerIcon) {
                                    const markerHtml = `
                                <div style="text-align: center;" id="marker">
                                    <div><img src="${markerIcon}" width="32" height="32" alt="${value.name}" /></div>
                                    <span>${value.name}</span>
                                </div>
                            `;
                                    mapView.Markers.add(
                                        value.center,
                                        markerHtml,
                                        { interactive: true }
                                    );
                                } else {
                                    const markerHtml = `
                                <div style="text-align: center;">
                                    <span>${value.name}</span>
                                </div>
                            `;
                                    mapView.Markers.add(
                                        value.center,
                                        markerHtml,
                                        { interactive: true }
                                    );
                                }
                                // mapView.Labels.add(value.center, value.name);

                                locations.push({ label: value?.name, value: value?.name, type: 'Object', center: value?.center, floor: value.floor });
                                checkPointData.push({ label: value?.name, value: value?.name, type: 'Object', center: value?.center, floor: value.floor });
                            }
                        });

                        mapData.getByType('door').forEach((value: Door) => {
                            if (mapView) {
                                if (value.name) {
                                    doorData.push(value.center);
                                } else {
                                    doorData.push(value.center);
                                }
                            }
                        });

                        mapData.getByType("connection").forEach((connection: Connection) => {
                            const coords = connection.coordinates.find(
                                (coord) => coord.floorId === mapView.currentFloor.id
                            );

                            if (coords) {
                                mapView.Labels.add(coords, connection.name);
                            }
                        });

                        mapData?.getByType('point-of-interest').forEach((value: PointOfInterest) => {
                            if (mapView && value.name) {
                                const matchingIcon = icons.find((icon) => icon.label.toLowerCase() === value.name.toLowerCase());
                                const markerIcon = matchingIcon ? matchingIcon.icon : null;

                                if (markerIcon) {
                                    const markerHtml = `
                                <div style="text-align: center;" id="marker">
                                    <div><img src="${markerIcon}" width="48" height="48" alt="${value.name}" /></div>
                                    <span>${value.name}</span>
                                </div>
                            `;
                                    mapView.Markers.add(
                                        value.coordinate,
                                        markerHtml,
                                        { interactive: true, dynamicResize: true, rank: 'high' }
                                    );
                                } else {
                                    const markerHtml = `
                                    <div style="text-align: center;" onClick="handleMarker(${JSON.stringify(value)})">
                                        <span>${value.name}</span>
                                    </div>
                                `;
                                    mapView.Markers.add(
                                        value.coordinate,
                                        markerHtml,
                                        { interactive: true, dynamicResize: true, rank: 'always-visible' }
                                    );
                                }

                                locations.push({ label: value?.name, value: value?.name, type: 'Point', center: value?.coordinate, floor: value.floor });
                                checkPointData.push({ label: value?.name, value: value?.name, type: 'Point', center: value?.coordinate, floor: value.floor });
                            }
                        });

                        mapData?.getByType('space').forEach((value: Space) => {
                            if (mapView && value.name) {
                                mapView.updateState(value, {
                                    interactive: true,
                                    hoverColor: '#51AB86',
                                });

                                const matchingIcon = icons.find((icon) => icon.label.toLowerCase() === value.name.toLowerCase());
                                const markerIcon = matchingIcon ? matchingIcon.icon : null;
                                if (markerIcon) {
                                    const markerHtml = `<div style="text-align: center;" id="marker">
                                    <div><img src="${markerIcon}" width="32" height="32" alt="${value.name}" /></div>
                                    <span>${value.name}</span>
                                </div>`;

                                    mapView.Markers.add(
                                        value.center,
                                        markerHtml,
                                        { interactive: true }
                                    );
                                }

                                mapView.Labels.add(value.center, value.name);
                                locations.push({ label: value?.name, value: value?.name, type: 'Space', center: value?.center, floor: value.floor });
                                spaceData.push({ label: value?.name, value: value?.name, type: 'Space', center: value?.center, floor: value.floor });
                            }
                        });

                        const uniqueLocations = new Map();
                        await locations.forEach((location) => {
                            uniqueLocations.set(location.value, location);
                        });

                        const floorData: FloorProps[] = [];
                        let matchFloorId = false;
                        mapData.getByType('floor').forEach((value: Floor) => {
                            floorData.push({ label: value.name, value: value.id, floorDetail: value });
                            if (floorId && (value.id === floorId)) {
                                mapView.setFloor(value);
                                matchFloorId = true;
                                setSelectedFloor({ label: value.name, value: value.id, floorDetail: value })
                            }
                        });

                        setFloorData(floorData);

                        if ((!floorId && floorData) || (!matchFloorId && floorData)) {
                            mapView.setFloor(floorData[0].floorDetail as Floor);
                            setSelectedFloor(floorData[0])
                        }

                        setMappedInData(mapData);
                        setMappedInView(mapView);
                        setLocationList(Array.from(uniqueLocations.values()));
                        setSpaceList(spaceData);
                        setCheckPointList(checkPointData);
                        setEnteranceList(doorData);
                    }
                };

                if (mapView && mapData) {
                    getAllLocations();
                }
            }
        } catch (_error) {
            setIsLoading(false);
            setError(true);
        }
    };

    const resetMarkers = () => {

        if (mappedInView && mappedInData) {
            mappedInView.Markers.removeAll();
            mappedInData.getByType('object').forEach((value: MapObject) => {
                if (mappedInView && value.name) {
                    if (value.name) {
                        mappedInView.updateState(value, {
                            interactive: true,
                            hoverColor: '#51AB86',
                        });
                    } else {
                        mappedInView.updateState(value, {
                            interactive: true,
                            hoverColor: '#51AB86',
                        });
                    }

                    const matchingIcon = icons.find((icon) => icon.label.toLowerCase() === value.name.toLowerCase());
                    const markerIcon = matchingIcon ? matchingIcon.icon : null;
                    if (markerIcon) {
                        const markerHtml = `
                        <div style="text-align: center;" id="marker">
                            <div><img src="${markerIcon}" width="32" height="32" alt="${value.name}" /></div>
                            <span>${value.name}</span>
                        </div>
                    `;
                        mappedInView.Markers.add(
                            value.center,
                            markerHtml,
                            { interactive: true }
                        );
                    } else {
                        const markerHtml = `
                        <div style="text-align: center;">
                            <span>${value.name}</span>
                        </div>
                    `;
                        mappedInView.Markers.add(
                            value.center,
                            markerHtml,
                            { interactive: true }
                        );
                    }
                }
            });

            mappedInData.getByType("connection").forEach((connection: Connection) => {
                const coords = connection.coordinates.find(
                    (coord) => coord.floorId === mappedInView.currentFloor.id
                );

                if (coords) {
                    mappedInView.Labels.add(coords, connection.name);
                }
            });

            mappedInData?.getByType('point-of-interest').forEach((value: PointOfInterest) => {
                if (mappedInView && value.name) {
                    const matchingIcon = icons.find((icon) => icon.label.toLowerCase() === value.name.toLowerCase());
                    const markerIcon = matchingIcon ? matchingIcon.icon : null;

                    if (markerIcon) {
                        const markerHtml = `
                        <div style="text-align: center;" id="marker">
                            <div><img src="${markerIcon}" width="48" height="48" alt="${value.name}" /></div>
                            <span>${value.name}</span>
                        </div>
                    `;
                        mappedInView.Markers.add(
                            value.coordinate,
                            markerHtml,
                            { interactive: true, dynamicResize: true, rank: 'high' }
                        );
                    } else {
                        const markerHtml = `
                            <div style="text-align: center;" onClick="handleMarker(${JSON.stringify(value)})">
                                <span>${value.name}</span>
                            </div>
                        `;
                        mappedInView.Markers.add(
                            value.coordinate,
                            markerHtml,
                            { interactive: true, dynamicResize: true, rank: 'always-visible' }
                        );
                    }
                }
            });

            mappedInData?.getByType('space').forEach((value: Space) => {
                if (mappedInView && value.name) {
                    mappedInView.updateState(value, {
                        interactive: true,
                        hoverColor: '#51AB86',
                    });

                    const matchingIcon = icons.find((icon) => icon.label.toLowerCase() === value.name.toLowerCase());
                    const markerIcon = matchingIcon ? matchingIcon.icon : null;
                    if (markerIcon) {
                        const markerHtml = `<div style="text-align: center;" id="marker">
                            <div><img src="${markerIcon}" width="32" height="32" alt="${value.name}" /></div>
                            <span>${value.name}</span>
                        </div>`;

                        mappedInView.Markers.add(
                            value.center,
                            markerHtml,
                            { interactive: true }
                        );
                    }
                    mappedInView.Labels.add(value.center, value.name);
                }
            });
        }

    }

    const resetMapView = () => {
        if (mappedInView && initialCameraState !== null) {
            const { zoomLevel, pitch, bearing } = initialCameraState;

            const transform = {
                zoomLevel: zoomLevel, // Zoom out (adjust zoom level as needed)
                pitch: pitch, // Adjust the pitch (camera angle) if necessary
                bearing: bearing, // Set bearing (rotation) to 0 for centering
                center: initialCenterState,
            };
            mappedInView.Camera.animateTo(transform, { duration: 4000 }); // Smooth animation for 1.5 seconds
        }
    };

    const customDestinationSelector = ({ props, state, methods, inputRef }: SelectRenderer<LocationProps> & {
        inputRef: React.RefObject<HTMLInputElement>;
    }) => {

        return (<div className="custom-react-dropdown-input-container" >
            {/* Input field for the dropdown */}
            <input
                ref={inputRef}
                type="text"
                value={selectedDestination?.label || state.search || ''}
                onChange={e => methods.setSearch(e)}
                placeholder="Select Destination"
                className={`custom-input ${selectedDestination && selectedDestination.value ? 'input-with-value' : ''}`}
            />

            {/* Search icon */}
            <div className='action-button-container'>
                {/* Show cross icon only when there is a selected destination */}
                {
                    selectedDestination && (
                        <div role='button' className='close-icon' onClick={() => {
                            setSelectedDestination(null);
                            clearDirection();
                        }}>
                            <OutlinedCloseIcon />
                        </div>
                    )
                }

                <div className='search-icon'>
                    <SearchIcon fill='#b2b3b5' height={16} width={16} />
                </div>
            </div>
        </div >)
    }

    function haversineDistance(source: Coordinate, target: Coordinate): number {
        const toRad = (x: number) => (x * Math.PI) / 180;

        const R = 6371; // Radius of the Earth in kilometers
        const dLat = toRad(target.latitude - source.latitude);
        const dLon = toRad(target.longitude - source.longitude);

        const lat1 = toRad(source.latitude);
        const lat2 = toRad(target.latitude);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in kilometers
    }

    function findNearestEntrance(source: Coordinate): Coordinate | null {
        if (enteranceList && enteranceList.length === 0) return null;

        else if (enteranceList) {
            let nearestEntrance = enteranceList[0];
            let minDistance = haversineDistance(source, nearestEntrance);

            enteranceList.forEach(entrance => {
                const distance = haversineDistance(source, entrance);
                if (distance < minDistance) {
                    minDistance = distance;
                    nearestEntrance = entrance;
                }
            });
            drawDirections(nearestEntrance);
            return nearestEntrance;
        }

        else {
            return null;
        }
    }

    const onNearestEnterance = async () => {
        setSelectedDestinationTab('Nearest Entrance');
        setShowDestinationDropDown(false);
        setSelectedDestination(null);
        if (selectedSource && selectedSource.center) {
            const nearestEntrance: Coordinate | null = await findNearestEntrance(selectedSource.center);
            if (nearestEntrance) {
                drawDirections(nearestEntrance);
            }
        }
    }

    const onMapAction = (type: string) => {
        if (mappedInView) {
            const transform: {
                pitch?: number;
                bearing?: number;
                zoomLevel?: number;
            } = {};
            switch (type) {
                case "pitch-up":
                    transform.pitch = mappedInView.Camera.pitch + 10;
                    break;
                case "pitch-down":
                    transform.pitch = mappedInView.Camera.pitch - 10;
                    break;
                case "bearing-left":
                    transform.bearing = (mappedInView.Camera.bearing - 45) % 360;
                    break;
                case "bearing-right":
                    transform.bearing = (mappedInView.Camera.bearing + 45) % 360;
                    break;
                case "zoom-in":
                    transform.zoomLevel = mappedInView.Camera.zoomLevel + 0.5;
                    break;
                case "zoom-out":
                    transform.zoomLevel = mappedInView.Camera.zoomLevel - 0.5;
                    break;
            }
            mappedInView.Camera.animateTo(transform, { duration: 1500 });
        }
    }

    // if (error && !isLoading) {
    //     return (
    //         <div className='mappedin-error-wrapper'>
    //             <div className='map-error-container error-container'>
    //                 <img className={'notFoundImage'} src={PineappleSorry} alt="404" />
    //                 <p>{`We're sorry, but the map could not be loaded at this time. Please contact Pineapple Support for assistance.`}</p>
    //             </div>

    //             <div className='map-footer'>
    //                 <div className='inner-container'>

    //                     <p></p>
    //                     {(agentNumber || rentalLink) && <div className='action-btn-wrapper'>
    //                         {rentalLink && <a className='rental-btn' href={rentalLink} target='_blank'>Apply Now</a>}

    //                         {agentNumber && <a href={`tel:${agentNumber}`} target='_blank' className='call-btn'>
    //                             <CallIcon />
    //                             <span>Call Agent</span>
    //                         </a>}

    //                     </div>}
    //                 </div>
    //             </div>

    //         </div >
    //     )

    return (
        <div className="mappedin-wrapper">
            {isLoading && <Loader />}

            {mappedInData && mappedInView && !isLoading && <>
                {showSelectorDropDown && (
                    <div
                        className="selector-backdrop"
                        onClick={() => {
                            setShowSelectorDropDown(false);
                        }}></div>
                )}

                <div
                    className={`custom-location-selector-wrapper ${showSelectorDropDown || showDestinationDropDown ? 'active' : ''} ${showDestinationSelector ? selectedDestinationTab === 'Selection' ? 'extra-bottom-space' : 'small-bottom-space' : ''
                        }`}>
                    <div
                        className={`custom-select-input ${selectedSource?.label ? 'border-bottom' : ''}`}
                        onClick={() => {
                            setShowSelectorDropDown(true);
                        }}>
                        <div className="search-input-wrapper">
                            <label>{showDestinationSelector ? 'To' : `Location`}</label>
                            <button
                                className="search-btn"
                                onClick={() => {
                                    setShowSelectorDropDown(!showSelectorDropDown);
                                }}>
                                {`📍 ${selectedSource ? selectedSource.label : 'Search location'}`}
                            </button>
                        </div>
                        <div className="action-btn-wrapper">
                            {!!selectedSource?.label && (
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearStats();
                                        setSelectedSource(null);
                                    }}>
                                    <OutlinedCloseIcon />
                                </div>
                            )}
                            {!showDestinationSelector && (
                                <div className="search-icon-wrapper">
                                    <SearchIcon />
                                </div>
                            )}
                        </div>
                    </div>
                    {showSelectorDropDown && (
                        <div className="custom-selector-dropdown">
                            <div className="tab-wrapper">
                                <button
                                    className={`tab ${selectedTab === 'rooms' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('rooms')}>
                                    🏢 Select Room
                                </button>
                                <button
                                    className={`tab ${selectedTab === 'points' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('points')}>
                                    🎈 Select Check Points
                                </button>
                            </div>

                            <div className="location-listing">
                                {selectedTab === 'rooms' ? (
                                    !!spaceList.length ? (
                                        spaceList.map((space: LocationProps, index: number) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                        if (space.floor !== selectedFloor?.floorDetail) {
                                                            setSelectedFloor({ label: space.floor.name, value: space.floor.id, floorDetail: space.floor });
                                                        }
                                                        setShowSelectorDropDown(false);
                                                        setSelectedSource(space);


                                                    }}>
                                                    {space.label}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div>No Rooms Available</div>
                                    )
                                ) : !!checkPointList.length ? (
                                    checkPointList.map((point: LocationProps, index: number) => {
                                        return (
                                            <div
                                                key={index}
                                                className="dropdown-item"
                                                onClick={() => {
                                                    setShowSelectorDropDown(false);
                                                    setSelectedSource(point);
                                                }}>
                                                {point.label}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div>No Rooms Available</div>
                                )}
                            </div>
                        </div>
                    )}

                    {!showDestinationSelector && selectedDestination === null && !!selectedSource && (
                        <div className="direction-wrapper">
                            <button
                                className="get-direction"
                                onClick={() => {
                                    resetMapView();
                                    setShowDestinationSelector(true);
                                    mappedInView.auto();
                                }}>
                                <span>Get Directions</span>
                                <DirectionIcon />
                            </button>
                        </div>
                    )}

                    {showDestinationSelector && selectedSource && (
                        <>
                            <div className="destination-selector-wrapper">
                                <label>From</label>
                                <div className="destination-tab-wrapper">
                                    <button
                                        className={`tab ${selectedDestinationTab === 'Dropped Pin' ? 'active' : ''}`}
                                        onClick={() => {
                                            resetMapView();
                                            setShowDestinationDropDown(false);
                                            setSelectedDestinationTab('Dropped Pin');
                                            setSelectedDestination(null);
                                        }}>
                                        Dropped Pin
                                    </button>
                                    <button
                                        className={`tab ${selectedDestinationTab === 'Selection' ? 'active' : ''}`}
                                        onClick={() => {
                                            setSelectedDestinationTab('Selection');
                                            setSelectedPin(false);
                                        }}>
                                        {' '}
                                        Selection
                                    </button>
                                    <button
                                        className={`tab ${selectedDestinationTab === 'Nearest Entrance' ? 'active' : ''}`}
                                        onClick={() => {
                                            onNearestEnterance();
                                            setSelectedPin(false);
                                        }}>
                                        Nearest Entrance
                                    </button>
                                </div>
                            </div>
                            {selectedDestinationTab === 'Selection' && (
                                <Select
                                    options={locationList ? filteredLocationData() : []}
                                    onDropdownClose={() => setShowDestinationDropDown(false)}
                                    onDropdownOpen={() => setShowDestinationDropDown(true)}
                                    values={selectedDestination ? [selectedDestination] : []}
                                    inputRenderer={customDestinationSelector}
                                    onChange={(value: LocationProps[]) => {
                                        if (value && value[0]) {
                                            setSelectedDestination(value[0]);
                                            drawDirections(value[0].center as Coordinate);
                                        }
                                    }}
                                    className="custom-react-dropdown-select"
                                    placeholder='Select Destination'
                                />
                            )}
                        </>
                    )}
                </div>

                <div className='level-selector'>
                    <span>Level</span>
                    <Select
                        options={floorData && floorData || []}
                        values={selectedFloor ? [selectedFloor] : []}
                        // inputRenderer={customDestinationSelector}
                        onChange={(value: FloorProps[]) => {
                            if (value && value[0]) {
                                setSelectedFloor(value[0]);
                                mappedInView.setFloor(value[0].floorDetail);
                            }
                        }}
                        className="custom-level-selector-dropdown"
                        placeholder=''
                        searchable={false}
                    />
                </div>

                {isVisible && (
                    <div
                        className="cursor-image"
                        style={{
                            position: 'absolute',
                            pointerEvents: 'none',
                            top: `${cursorPosition.y}px`,
                            left: `${cursorPosition.x}px`,
                            width: '48px',
                            height: '48px',
                            zIndex: 5,
                            transform: 'translate(-50%, -50%)',
                        }}>
                        <img src={ManWithParachute} alt="parachute-icon" width={48} height={48} />
                    </div>
                )}

                <div className='map-info-butto-container'>
                    <button className='map-info-btn'><InfoIcon /></button>
                </div>

                <div className='map-action-butto-container'>
                    <button className='map-action-btn' onClick={() => resetMapView()}><ZoomResetIcon /></button>
                    <button className='map-action-btn' onClick={() => onMapAction('zoom-out')}><ZoomOutIcon /></button>
                    <button className='map-action-btn' onClick={() => onMapAction('zoom-in')}><ZoomInIcon /></button>
                </div>
            </>}

            {error && !isLoading && <div className='mappedin-map mappedin-error-wrapper'>
                <div className='map-error-container error-container'>
                    <img className={'notFoundImage'} src={PineappleSorry} alt="404" />
                    <p>{`We're sorry, but the map could not be loaded at this time. Please contact Pineapple Support for assistance.`}</p>
                </div>
            </div >
            }

            {!error && <div id="mappedin-map" className="mappedin-map" ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />}
            <div className='map-footer'>
                <div className='inner-container'>

                    <p></p>
                    {(agentNumber || rentalLink) && <div className='action-btn-wrapper'>
                        {rentalLink && <a className='rental-btn' href={rentalLink} target='_blank'>Apply Now</a>}

                        {agentNumber && <a href={`tel:${agentNumber}`} target='_blank' className='call-btn'>
                            <CallIcon />
                            <span>Call Agent</span>
                        </a>}

                    </div>}
                </div>
            </div>
        </div >
    );
};

export default MappedIn;
