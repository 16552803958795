import React from "react";
import Slider from "react-slick";
import styles from "./AmenitySwiper.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AmenitiesObject } from "../../redux/appointment/actions/appointment.action";
import CloseIcon from "../icons/CloseIcon";

interface Props {
  locationData: AmenitiesObject | null;
  icon: string | null;
}

const AmenitySwiper = ({ locationData, icon }: Props) => {
  if (!locationData) return null;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={`${styles.amenitySwiper} amenities-page`}>
      {icon && <div className={styles.amenityHeader}>
        <div className={styles.leftContainer}>

          <div className={styles.iconWrapper}>
            <img src={icon} alt='icon' width={16} height={16} />
          </div>

          <h2 className={styles.title}>{locationData.name}</h2>
        </div>
        <div className={`${styles.closeIcon} close-icon`}>
          <CloseIcon width={24} height={24} />
        </div>

      </div>}

      {!!locationData.mediaFiles?.length && <Slider className={`${styles.slider} amenity-slider`} {...sliderSettings}>
        {locationData.mediaFiles.map((media, index) => (
          <div key={index}>
            {media.mimetype.startsWith("image") ? (
              <img
                className={styles.mediaImage}
                src={media.url}
                alt="Media"
              />
            ) : media.mimetype.startsWith("video") ? (
              <video className={styles.mediaVideo} controls>
                <source
                  src={media.url}
                  type={`video/${media.mimetype.split("/")[1]}`}
                />
                Your browser does not support the video tag.
              </video>
            ) : null}
          </div>
        ))}
      </Slider>}

      <div className={styles.contentWrapper}>

        {locationData.description && (
          <p
            className={`${styles.description} ${locationData?.mediaFiles?.length === 0 && !locationData.amenityWebLinkTitle ? styles.fullHeight : locationData?.mediaFiles?.length === 0 && locationData.amenityWebLinkTitle ? styles.withURL : ''}`}>
            {locationData.description}
          </p>
        )}
        {locationData.amenityWebLink && locationData.amenityWebLinkTitle && (
          <a
            className={styles.link}
            href={locationData.amenityWebLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {locationData.amenityWebLinkTitle}
          </a>
        )}
      </div>
    </div >
  );
};

export default AmenitySwiper;
