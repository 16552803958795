import React from "react";

interface LeftChevronProps {
  width?: number;
  height?: number;
  fill?: string;
}

const LeftChevron = ({ width, height, fill }: LeftChevronProps) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4657 4.96997C15.6735 4.9654 15.8776 5.02564 16.0495 5.1424C16.2215 5.25917 16.3528 5.4267 16.4252 5.62154C16.4976 5.81638 16.5074 6.0289 16.4534 6.22962C16.3995 6.43034 16.2843 6.60917 16.1239 6.74139L10.0047 11.9836L16.1239 17.2239C16.2348 17.3054 16.3277 17.409 16.3968 17.5281C16.4659 17.6472 16.5096 17.7793 16.5252 17.9161C16.5409 18.0529 16.5281 18.1914 16.4877 18.323C16.4474 18.4546 16.3802 18.5765 16.2906 18.681C16.2009 18.7855 16.0907 18.8703 15.9667 18.9302C15.8427 18.9901 15.7077 19.0237 15.5702 19.029C15.4326 19.0343 15.2954 19.0112 15.1672 18.9611C15.039 18.9109 14.9225 18.8348 14.8251 18.7375L7.8192 12.7433C7.70918 12.6494 7.62084 12.5329 7.56026 12.4016C7.49968 12.2702 7.46831 12.1273 7.46831 11.9826C7.46831 11.838 7.49968 11.6951 7.56026 11.5637C7.62084 11.4324 7.70918 11.3157 7.8192 11.2219L14.8251 5.22188C15.002 5.06468 15.229 4.97531 15.4657 4.96974V4.96997Z"
        fill={fill || "#8C8D90"}
      />
    </svg>
  );
};

export default LeftChevron;
