import React, { useEffect, useRef, useState } from "react";
import SwiperIcon from '../../images/swiper_icon.svg';
import AmazonKey from '../../images/amazon_key.svg';
import styles from "./SwiperButton.module.css";

interface SwiperButtonProps {
  onDragend: () => void;
  loading?: boolean
}

const SwiperButton = ({ onDragend, loading }: SwiperButtonProps) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const controlRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const handleDragStart = (event: React.MouseEvent | React.TouchEvent) => {

    if (isCompleted) return;

    const container = containerRef.current;
    const control = controlRef.current;
    if (!container || !control) return;

    const containerRect = container.getBoundingClientRect();
    const controlRect = control.getBoundingClientRect();

    const startX =
      "touches" in event
        ? event.touches[0].clientX - controlRect.left
        : event.clientX - controlRect.left;

    const onDrag = (moveEvent: MouseEvent | TouchEvent) => {
      const currentX =
        "touches" in moveEvent
          ? moveEvent.touches[0].clientX
          : (moveEvent as MouseEvent).clientX;

      const offsetX = currentX - containerRect.left - startX;
      const maxX = containerRect.width - controlRect.width;
      const clampedX = Math.max(0, Math.min(offsetX, maxX));

      control.style.transform = `translateX(${clampedX}px)`;

      // Update overlay and text opacity
      const progress = clampedX / maxX;
      if (textRef.current) textRef.current.style.opacity = `${1 - (progress)}`;

      if (progress >= 1) {
        setIsCompleted(true);
        onDragend()
        if (overlayRef.current) overlayRef.current.style.opacity = `${progress}`;
        control.style.transform = `translateX(${maxX}px)`;
        document.removeEventListener("mousemove", onDrag);
        document.removeEventListener("mouseup", onDragEnd);
        document.removeEventListener("touchmove", onDrag);
        document.removeEventListener("touchend", onDragEnd);
      }
    };


    const onDragEnd = () => {
      if (!isCompleted) {
        control.style.transform = `translateX(0px)`;
        if (textRef.current) textRef.current.style.opacity = "1";
        if (overlayRef.current) overlayRef.current.style.opacity = "0";
      }
      document.removeEventListener("mousemove", onDrag);
      document.removeEventListener("mouseup", onDragEnd);
      document.removeEventListener("touchmove", onDrag);
      document.removeEventListener("touchend", onDragEnd);
    };

    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", onDragEnd);
    document.addEventListener("touchmove", onDrag);
    document.addEventListener("touchend", onDragEnd);
  };

  useEffect(() => {
    if (!loading && isCompleted) {
      setIsCompleted(false)
    }
  }, [loading, isCompleted])

  return (
    <div className={styles.container} ref={containerRef}>
      {
        isCompleted || <div
          className={styles.control}
          ref={controlRef}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
        >
          <div className={styles.text} ref={textRef}>
            <div className={styles.swiperWrapper}>
              <span className={styles.swipeText}>
                Swipe
              </span>
              <img className={styles.swipeIcon} src={SwiperIcon} alt="swiper" width={31.69} height={17.5} />
            </div>
            <div className={styles.amazonKeyWrapper}>
              <img src={AmazonKey} width={30} height={30} alt="amazon key" />
              <span>Unlock The Door</span>
            </div>
          </div>
        </div>
      }
      <div className={`${styles.completedOverlay} ${isCompleted ? styles.completed : ''}`} ref={overlayRef}>
        <p className={styles.OpeningText}>Unlocking...</p>
      </div>
    </div >
  );
};

export default SwiperButton;
