import { useState, useEffect } from "react";

interface Coordinates {
  latitude: number | null;
  longitude: number | null;
}

interface GeolocationState {
  isSupported: boolean;
  isEnabled: boolean;
  coordinates: Coordinates;
  error: string | null;
}

function useGeolocation(): GeolocationState {
  const [isSupported, setIsSupported] = useState<boolean>(true);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<Coordinates>({ latitude: null, longitude: null });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      setIsSupported(false);
      setError("Geolocation is not supported by this browser.");
      return;
    }

    const geoSuccess = (position: GeolocationPosition) => {
      setIsEnabled(true);
      setCoordinates({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    const geoError = (err: GeolocationPositionError) => {
      setIsEnabled(false);
      setError(err.message);
    };

    navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
  }, []);

  return { isSupported, isEnabled, coordinates, error };
}

export default useGeolocation;
