import React from 'react';

interface SearchIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const SearchIcon = ({ width = 21, height = 21, fill = 'white' }: SearchIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57251 1.68945C5.23363 1.68945 1.69922 5.22386 1.69922 9.56276C1.69922 13.9016 5.23363 17.4429 9.57251 17.4429C11.4258 17.4429 13.1302 16.7934 14.4773 15.7151L17.7568 18.9929C17.9223 19.1516 18.1434 19.2391 18.3726 19.2368C18.6019 19.2344 18.8211 19.1424 18.9833 18.9804C19.1455 18.8184 19.2379 18.5994 19.2405 18.3701C19.2432 18.1409 19.156 17.9197 18.9976 17.754L15.718 14.4744C16.7973 13.1253 17.4475 11.4182 17.4475 9.56276C17.4475 5.22386 13.9114 1.68945 9.57251 1.68945ZM9.57251 3.43948C12.9656 3.43948 15.6958 6.16965 15.6958 9.56276C15.6958 12.9559 12.9656 15.6929 9.57251 15.6929C6.1794 15.6929 3.44922 12.9559 3.44922 9.56276C3.44922 6.16965 6.1794 3.43948 9.57251 3.43948Z"
      fill={fill}
    />
  </svg>
);

export default SearchIcon;
