import { AnyIfEmpty } from "react-redux";
import { CLEAR_LOCATION_AMENITIES, GET_LOCATION_AMENITIES_FAILURE, GET_LOCATION_AMENITIES_REQUEST, GET_LOCATION_AMENITIES_SUCCESS } from "../constants/amenities.constants";

const initialState = {
  loading: false,
  data: [],
  errorMsg: '',
};

const amenitiesReducer = (state = initialState, action: { type: string; payload: AnyIfEmpty<object> }) => {
  switch (action.type) {

    case GET_LOCATION_AMENITIES_REQUEST:
      return {
        loading: true,
        data: [],
        errorMsg: ''
      }

    case GET_LOCATION_AMENITIES_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        errorMsg: ''

      }

    case GET_LOCATION_AMENITIES_FAILURE:
      return {
        loading: false,
        data: [],
        errorMsg: action.payload
      }

    case CLEAR_LOCATION_AMENITIES:
      return {
        loading: false,
        data: [],
        errorMsg: ""
      }

    default:
      return state;
  }
}

export default amenitiesReducer;