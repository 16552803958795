import React from 'react'
import Amenities from '../components/amenities/Amenities'

const AmenitiesPage = () => {
  return (
    <div className=''>
      <Amenities />
    </div>
  )
}

export default AmenitiesPage
