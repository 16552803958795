import React from "react";

interface ZoomResetIconProps {
  width?: number;
  height?: number;
  strokeColor?: string;
  strokeWidth?: number;
  fillColor?: string;
}

const ZoomResetIcon = ({ width = 23, height = 15, strokeColor = "#191C21", strokeWidth = 2, fillColor = "#191C21" }: ZoomResetIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3.29412C6 2.5794 6.5794 2 7.29412 2H15.7059C16.4206 2 17 2.5794 17 3.29412V11.7059C17 12.4206 16.4206 13 15.7059 13H7.29412C6.5794 13 6 12.4206 6 11.7059V3.29412Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <rect
        x="21"
        width="2"
        height="15"
        rx="1"
        fill={fillColor}
      />
      <rect
        width="2"
        height="15"
        rx="1"
        fill={fillColor}
      />
    </svg>
  );
};

export default ZoomResetIcon;
