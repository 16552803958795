import { he } from 'date-fns/locale';
import React from 'react';

interface OutlinedCloseIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const OutlinedCloseIcon = ({ width = 14, height = 14, fill = "#B2B3B5", }: OutlinedCloseIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.419076 14.0906C0.681298 14.3527 1.0369 14.5 1.40768 14.5C1.77846 14.5 2.13407 14.3527 2.39629 14.0906L7.00093 9.48595L11.6056 14.0906C11.8693 14.3453 12.2225 14.4862 12.5891 14.4831C12.9558 14.4799 13.3065 14.3328 13.5657 14.0736C13.825 13.8143 13.9721 13.4636 13.9753 13.097C13.9784 12.7303 13.8375 12.3771 13.5828 12.1134L8.97814 7.50874L13.5828 2.9041C13.7163 2.77511 13.8229 2.62081 13.8961 2.45021C13.9694 2.27962 14.008 2.09613 14.0096 1.91046C14.0112 1.7248 13.9759 1.54067 13.9055 1.36882C13.8352 1.19697 13.7314 1.04085 13.6001 0.909556C13.4688 0.778264 13.3127 0.674435 13.1408 0.604127C12.969 0.533819 12.7849 0.498439 12.5992 0.500053C12.4135 0.501666 12.2301 0.54024 12.0595 0.613525C11.8889 0.686809 11.7346 0.793335 11.6056 0.926888L7.00093 5.53153L2.39629 0.926888C2.2673 0.793335 2.113 0.686809 1.9424 0.613525C1.7718 0.54024 1.58832 0.501666 1.40265 0.500053C1.21698 0.498439 1.03285 0.533819 0.861006 0.604127C0.689159 0.674435 0.533034 0.778264 0.401743 0.909556C0.270452 1.04085 0.166623 1.19697 0.0963146 1.36882C0.0260063 1.54067 -0.0093731 1.7248 -0.0077597 1.91046C-0.00614631 2.09613 0.0324279 2.27962 0.105712 2.45021C0.178996 2.62081 0.285523 2.77511 0.419076 2.9041L5.02372 7.50874L0.419076 12.1134C0.156933 12.3756 0.00966891 12.7312 0.00966891 13.102C0.00966891 13.4728 0.156933 13.8284 0.419076 14.0906Z"
      fill={fill}
    />
  </svg>
);

export default OutlinedCloseIcon;
