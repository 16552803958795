import React from 'react'
import LocationDetail from '../components/locationDetails/LocationDetail'

const MapDetails = () => {

  return (
    <LocationDetail />
  )
}

export default MapDetails
