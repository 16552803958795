import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AmenitiesObject, AppointmentMapDetails, getAppointmentMapDetails } from '../../redux/appointment/actions/appointment.action';
import { AppDispatch } from '../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import LocationLoader from './LocationLoader';
import AmenitySwiper from './AmenitySwiper';
import { icons } from '../mappit/mappedIn';

const LocationDetail = () => {
  const { id, lat, long } = useParams();

  const [amenitiesDetails, setAmenitiesDetails] = useState<null | AmenitiesObject[]>(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const [locationData, setLocationData] = useState<null | AmenitiesObject>(null);
  const [selectedMapIcon, setSelectedMapIcon] = useState<null | string>(null)



  const onSuccess = async (appointment: AppointmentMapDetails, amenity: AmenitiesObject[] | null) => {
    setIsLoading(false);
    if (amenity) {
      await setAmenitiesDetails(amenity);
    }
  };

  const onFailure = (message: string, statusCode: string) => {
    // setIsLoading(false);
  };

  useEffect(() => {
    if (id && id?.length) {
      setIsLoading(true);
      dispatch(getAppointmentMapDetails(id, onSuccess, onFailure));
    }
  }, [id]);

  const findNearestWithPrecision = (clickedLat: number, clickedLon: number) => {
    const maxPrecision = 15; // Maximum precision to consider
    let currentPrecision = 4; // Start at 4 decimal points

    const toFixedPrecision = (value: number, decimals: number) => parseFloat(value.toString()).toFixed(decimals);

    let matches: AmenitiesObject[] = [];
    do {
      const clickedLatRounded = toFixedPrecision(clickedLat, currentPrecision);
      const clickedLonRounded = toFixedPrecision(clickedLon, currentPrecision);

      matches = amenitiesDetails ? amenitiesDetails.filter((amenity: AmenitiesObject) => {
        const amenityLatRounded = toFixedPrecision(amenity.latitude, currentPrecision);
        const amenityLonRounded = toFixedPrecision(amenity.longitude, currentPrecision);
        return clickedLatRounded === amenityLatRounded && clickedLonRounded === amenityLonRounded;
      }) : [];

      if (matches.length <= 1) break; // Stop if only one or no match is found
      currentPrecision++; // Increase precision if there are multiple matches
    } while (currentPrecision <= maxPrecision);

    setLocationData(matches.length === 1 ? {
      ...matches[0]
    } : null);

    if (matches.length === 1) {
      const matchingIcon: {
        label: string, icon: string
      } | null = icons.find((icon) => icon.label.toLowerCase() === matches[0]?.name.toLowerCase()) || null;
      setSelectedMapIcon(matchingIcon?.icon || null);
    }
  };

  useEffect(() => {
    if (amenitiesDetails && lat && long) {
      findNearestWithPrecision(Number(lat), Number(long))
    }
  }, [amenitiesDetails, lat, long])

  if (isLoading) {
    return <LocationLoader />
  }

  if (locationData && !isLoading) {
    return <AmenitySwiper locationData={locationData} icon={selectedMapIcon} />
  }

  return <div></div>
}

export default LocationDetail
