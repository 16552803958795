// ZoomInIcon.tsx
import React from "react";

interface ZoomInIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const ZoomInIcon = ({ width = 19, height = 19, fillColor = "#191C21" }: ZoomInIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0499 8.55H10.4501V0.949942C10.4501 0.425656 10.0244 0 9.49994 0C8.97566 0 8.55 0.425656 8.55 0.949942V8.55H0.949942C0.425656 8.55 0 8.97566 0 9.49994C0 10.0244 0.425656 10.4501 0.949942 10.4501H8.55V18.0499C8.55 18.5744 8.97566 19.0001 9.49994 19.0001C10.0244 19.0001 10.4501 18.5744 10.4501 18.0499V10.4501H18.0499C18.5744 10.4501 19.0001 10.0244 19.0001 9.49994C19.0001 8.97566 18.5744 8.55 18.0499 8.55Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ZoomInIcon;
