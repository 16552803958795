import axios from 'axios';
import React, { Dispatch } from 'react';
import { AnyIfEmpty } from 'react-redux';
import {
    GET_AUTH_FAILURE,
    GET_AUTH_REQUEST,
    GET_AUTH_SUCCESS,
    GET_SIGNUP_REQUEST,
    GET_SIGNUP_SUCCESS,
    GET_SIGNUP_FAILURE,
    RESET_VALIDATION_MESSAGE,
} from '../constants/auth.constant';
import { NavigateFunction } from 'react-router-dom';

// Get Auth Request
const getAuthRequest = () => {
    return {
        type: GET_AUTH_REQUEST,
    };
};

const getAuthSuccess = (authData: {
    message: string;
    statusCode: number;
    token: string;
    openViaChatBotPropertyId?: string | null;
    openViaSlug?: string | null;
    unitMapId?: string | null;
    leasingAgentNumber?: string | number;
    user: {
        email: string;
        fullName: string;
        id: string;
        image_url: string;
        resetToken: string;
        resetTokenExpirationAt: string;
    };
}) => {
    return {
        type: GET_AUTH_SUCCESS,
        payload: authData,
    };
};

const getAuthFailure = (authError: string) => {
    return {
        type: GET_AUTH_FAILURE,
        payload: authError,
    };
};

// Signup Request
const getSignupRequest = () => {
    return {
        type: GET_SIGNUP_REQUEST,
    };
};

const getSignupSuccess = (signupData: AnyIfEmpty<object>) => {
    return {
        type: GET_SIGNUP_SUCCESS,
        payload: signupData,
    };
};

const getSignupFailure = (signupError: string) => {
    return {
        type: GET_SIGNUP_FAILURE,
        payload: signupError,
    };
};

export const resetValidationMessage = () => {
    return {
        type: RESET_VALIDATION_MESSAGE,
    };
};

// Get Auth Dispatch
export const getAuth = (loginDetails: AnyIfEmpty<object>, onSuccess: AnyIfEmpty<object>, navigate?: NavigateFunction) => {
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getAuthRequest());
        axios
            .post(`/visitor/login`, loginDetails)
            .then((response) => {
                if (response?.status === 200) {
                    dispatch(getAuthSuccess(response.data));
                    response.data.token && localStorage.setItem('visitorAccessToken', response.data.token);
                    response.data.visitor && localStorage.setItem('visitor', JSON.stringify(response.data.visitor));
                    onSuccess(response.data);
                }
            })
            .catch((error) => {
                if (error.response?.status === 406) {
                    dispatch(getAuthFailure(error?.response?.data?.message))
                    navigate?.(`/reactivate-account/${loginDetails?.email}`)
                } else {
                    dispatch(getAuthFailure(error?.response?.data?.message))
                }
            });
    };
};

// Get Register Dispatch
export const getRegister: AnyIfEmpty<object> = (
    signupDetails: { [x: string]: string; confirmPassword: string },
    onSuccess: { (): void; (arg0: string): void },
    setShowRentalTerm?: React.Dispatch<React.SetStateAction<boolean>>,
    setCCVerification?: React.Dispatch<React.SetStateAction<boolean>>,
    setIdVerification?: React.Dispatch<React.SetStateAction<boolean>>,
    setCreditCardDetail?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const { confirmPassword, ...rest } = signupDetails;
    return (dispatch: Dispatch<AnyIfEmpty<object>>) => {
        dispatch(getSignupRequest());
        axios
            .post(`/visitor/register`, rest)
            .then((response) => {
                if (response.data.statusCode === 200) {
                    dispatch(getSignupSuccess(response.data));
                    localStorage.setItem('visitorAccessToken', response.data.token);
                    localStorage.setItem('visitor', JSON.stringify(response.data.visitor));
                    onSuccess(response.data);
                } else {
                    setShowRentalTerm?.(true);
                    setCCVerification?.(false);
                    setIdVerification?.(true);
                    setCreditCardDetail?.(false);
                    dispatch(getSignupFailure(response?.data?.message));
                }
            })
            .catch((error) => {
                dispatch(getSignupFailure(error?.response?.data?.message));
            });
    };
};

export const uploadImages = async (imageFiles: File, folderName: string) => {
    const formData = new FormData();
    formData.append('file', imageFiles);
    const imageData = await axios.post(`/admin/upload/file?folderName=${folderName}`, formData);
    if (imageData?.data?.length) {
        return imageData?.data[0]?.location;
    }

    return;
};