import React from 'react';
import styles from './LocationLoader.module.css';

const LocationLoader = () => {
  return (
    <div className={styles.swiperSkeletonContainer}>
      <div className={styles.swiperHeader}>
        <div className={styles.leftContainer}>
          <div className={`${styles.skeletonIcon} ${styles.shimmer}`}></div>
          <div className={`${styles.skeletonTitle} ${styles.shimmer}`}></div>
        </div>

        <div className={`${styles.skeletoncloser} ${styles.shimmer}`}></div>

      </div>

      <div className={styles.swiperSkeleton}>
        {Array.from({ length: 1 }).map((_, index) => (
          <div key={index} className={`${styles.skeletonSlide} ${styles.shimmer}`}></div>
        ))}
      </div>

      <div className={styles.textContainer}>
        <div className={`${styles.skeletonDescriptionGroup}`}>
          <div className={`${styles.skeletonDescription} ${styles.shimmer}`}></div>
          <div className={`${styles.skeletonDescription} ${styles.shimmer}`}></div>
          <div className={`${styles.skeletonDescription} ${styles.shimmer}`}></div>
        </div>
        <div className={`${styles.skeletonUrl} ${styles.shimmer}`}></div>
      </div>
    </div>
  );
};

export default LocationLoader;
