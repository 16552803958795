import { AnyIfEmpty } from "react-redux";
import { CLEAR_LOCATION_AMENITIES, GET_LOCATION_AMENITIES_FAILURE, GET_LOCATION_AMENITIES_REQUEST, GET_LOCATION_AMENITIES_SUCCESS } from "../constants/amenities.constants";
import axios, { Axios, AxiosError, AxiosResponse } from "axios";

const getLocationAmenitiesRequest = () => {
  return {
    type: GET_LOCATION_AMENITIES_REQUEST,
  };
};


const getLocationAmenitiesSuccess = (locationByIdData: AnyIfEmpty<object>) => {
  return {
    type: GET_LOCATION_AMENITIES_SUCCESS,
    payload: locationByIdData,
  };
};

const getLocationAmenitiesFailure = (locationByIdError: string) => {
  return {
    type: GET_LOCATION_AMENITIES_FAILURE,
    payload: locationByIdError,
  };
};

export const clearLocationAmenities = () => {
  return {
    type: CLEAR_LOCATION_AMENITIES,
  };
}

export const getLocationAmenities = (locationId: string) => {
  return async (dispatch: AnyIfEmpty<object>) => {
    dispatch(getLocationAmenitiesRequest());
    return await axios
      .get(`/amenities/${locationId}`)
      .then((response: AxiosResponse) => {
        if (response.data.statusCode === 200) {
          dispatch(getLocationAmenitiesSuccess(response.data));
        }
      })
      .catch((err) => dispatch(getLocationAmenitiesFailure(err?.response?.data?.message)));
  };
}