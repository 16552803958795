import React, { useEffect, useState } from 'react'
import styles from './Amenities.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, IRootState } from '../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import { getLocationAmenities } from '../../redux/amenities/actions/amenities.action';
import { useSelector } from 'react-redux';
import LeftChevron from '../icons/LeftChevron';
import Loader from '../common/loader/Loader';

// amenities images
import ClubHouse from '../../images/amenities/theatre.svg';
import BusinessCenter from '../../images/amenities/business.svg';
import Gym from '../../images/amenities/GYM.svg';
import DogPark from '../../images/amenities/Dog-Park.svg';
import EVChargingStation from '../../images/amenities/Electric-Vehicle-Charging-Station.svg';
import LaundryRoom from '../../images/amenities/Laundry-Room.svg';
import RentalSpace from '../../images/amenities/Storage-Space-Rentals.svg';
import PackageLockers from '../../images/amenities/Package-Locker.svg';
import Pool from '../../images/amenities/Pool.svg';
import PetWashingCenter from '../../images/amenities/Pet-Washing-Station.svg';
import PicnicArea from '../../images/amenities/Picnic-Area.svg';
import PlayGround from '../../images/amenities/Playground.svg';
import GardenIcon from '../../images/amenities/Garden.svg';
import BBQ from '../../images/amenities/Picnic-Area.svg';
import CloseIcon from '../icons/CloseIcon';

export interface MediaFile {
  url: string
  size: number;
  mimetype: "video/mp4" | "image/jpeg" | "image/png" | "video/mov"
}

export interface IconData {
  name: string | null;
  icon: string;
  latitude?: number | null;
  longitude?: number | null;
  mediaFiles?: MediaFile[] | [] | null;
  amenityWebLink?: string | null;
  amenityWebLinkTitle?: string | null;
  location?: string | null;
  description?: string | null;
  id?: string;
}

const initialAmenities: IconData[] = [
  { name: 'playground', icon: PlayGround, mediaFiles: null },
  { name: 'clubhouse', icon: ClubHouse, mediaFiles: null },
  { name: 'business center', icon: BusinessCenter, mediaFiles: null },
  { name: 'gym', icon: Gym, mediaFiles: null },
  { name: 'fitness center', icon: Gym, mediaFiles: null },
  { name: 'dog park', icon: DogPark, mediaFiles: null },
  { name: 'pet park', icon: DogPark, mediaFiles: null },
  { name: 'electric vehicle charging station', icon: EVChargingStation, mediaFiles: null },
  { name: 'laundry room', icon: LaundryRoom, mediaFiles: null },
  { name: 'laundry', icon: LaundryRoom, mediaFiles: null },
  { name: 'storage space rentals', icon: RentalSpace, mediaFiles: null },
  { name: 'package locker', icon: PackageLockers, mediaFiles: null },
  { name: 'pool', icon: Pool, mediaFiles: null },
  { name: 'swimming pool', icon: Pool, mediaFiles: null },
  { name: 'pet washing station', icon: PetWashingCenter, mediaFiles: null },
  { name: 'picnic area', icon: PicnicArea, mediaFiles: null },
  { name: 'garden', icon: GardenIcon, mediaFiles: null },
  { name: 'grocery store', icon: BusinessCenter, mediaFiles: null },
  { name: 'BBQ', icon: BBQ, mediaFiles: null }
];

const Amenities = () => {
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { data: amenitiesData, loading: amenitiesLoading, errorMsg: amenitiesError } = useSelector((state: IRootState) => state.amenitiesReducer);

  const [amenitiesList, setAmenitiesList] = useState<IconData[] | null>(null)
  const [selectedAmenity, setSelectedAmenity] = useState<IconData | null>(null);
  // const [showModalPopup, setShowModalPopup] = useState(false);
  const [selectedAmenityMedia, setSelectedAmenityMedia] = useState<null | MediaFile>(null);

  useEffect(() => {
    if (params && params?.id) {
      dispatch(getLocationAmenities(params?.id));
    }
  }, [params]);

  useEffect(() => {
    if (selectedAmenityMedia) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [selectedAmenityMedia])


  useEffect(() => {
    if (amenitiesData?.amenities) {
      const updatedAmenityList = amenitiesData.amenities.map((value: {
        latitude: number | null;
        longitude: number | null;
        mediaFiles: (string | File)[] | null;
        amenityWebLink: string | null;
        amenityWebLinkTitle: string | null;
        location: string | null;
        description: string | null;
        id: string;
        name: string | null;
      }) => {
        const fetchedData: IconData | undefined = initialAmenities.find((amenity: IconData) => amenity.name?.toString()?.toLowerCase() === value.name?.toString().toLowerCase());
        if (fetchedData) {
          return {
            ...fetchedData,
            latitude: value?.latitude,
            longitude: value?.longitude,
            mediaFiles: value?.mediaFiles,
            amenityWebLink: value?.amenityWebLink,
            amenityWebLinkTitle: value?.amenityWebLinkTitle || [],
            location: value?.location,
            description: value?.description,
            id: value?.id
          };
        }
        return null;
      }).filter(Boolean);
      setAmenitiesList(updatedAmenityList);
    }
  }, [amenitiesData]);

  return (
    <div className={`${styles.amenitiesPagecontainer} amenities-page`} >
      {amenitiesLoading && <Loader />}
      {(!amenitiesLoading && !amenitiesError) && <div className={styles.innerContainer}>

        {/* back btn */}
        <button className={styles.backBtn}
          onClick={() => {
            if (selectedAmenity) {
              setSelectedAmenity(null);
            } else {
              const currentPath = window.location.pathname?.toString()?.replace('/amenities', '');
              navigate(currentPath)
            }
          }}>
          <LeftChevron />
          <span>Back</span>
        </button>

        {/* Location name */}
        {amenitiesData?.location?.locationName && <h3>{selectedAmenity === null ? amenitiesData?.location?.locationName : `${selectedAmenity?.name} at ${amenitiesData?.location?.locationName}`}</h3>}

        {/* amenities list */}
        {!!amenitiesList?.length && selectedAmenity === null && <div className={styles.amenitiesListing}>
          {amenitiesList?.map((value: IconData, index: number) => {
            return (<div
              className={`${styles.amenityBox}`}
              key={index}
              onClick={() => setSelectedAmenity(value)}
            >
              <img src={value.icon} alt={value.name || ''} width={48} height={48} />
              {value?.name && <p>{value?.name}</p>}
            </div>)
          })}
        </div>}

        {selectedAmenity &&
          <div className={styles.amenityDetailWrapper}>

            {/* media files */}
            <div className={styles.amenityMediaWrapper}>
              {selectedAmenity?.mediaFiles && selectedAmenity?.mediaFiles?.map((value: MediaFile, index: number) => {
                if (value.mimetype === 'image/jpeg' || value.mimetype === 'image/png') {
                  return <div className={styles.amenityMedia} onClick={() => setSelectedAmenityMedia(value)}>
                    <img src={value.url} alt={selectedAmenity?.name || ''} width={450} height={214} />
                  </div>
                } else {
                  return <div className={styles.amenityMedia} onClick={() => setSelectedAmenityMedia(value)}>
                    <video className={styles.mediaPreview} width={450} height={214}>
                      <source src={value.url} type={value.mimetype} />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                }
              })}
            </div>

            {/* description */}
            {selectedAmenity?.description && <div className={styles.amenityDetail}>
              <p>{selectedAmenity?.description}</p>
            </div>}

            {/* website link */}
            {selectedAmenity?.amenityWebLink && selectedAmenity?.amenityWebLinkTitle &&
              <a href={selectedAmenity?.amenityWebLink} target='_blank' className={styles.amenityLink}>{`${selectedAmenity?.amenityWebLinkTitle} URL`}</a>
            }
          </div>
        }

        {selectedAmenityMedia &&
          <>
            <div className={styles.amenityLightBoxView}></div>
            <div className={styles.lightBoxMedia}>
              <div className={styles.mediaTag}>
                <div className={styles.closeIcon} role='button' onClick={() => setSelectedAmenityMedia(null)}>
                  <CloseIcon />
                </div>
                {
                  (selectedAmenityMedia?.mimetype === "image/jpeg" || selectedAmenityMedia.mimetype === "image/png") ?
                    <img src={selectedAmenityMedia?.url} alt="amenity" />
                    :
                    <video controls >
                      <source src={selectedAmenityMedia.url} type={selectedAmenityMedia.mimetype} />
                      Your browser does not support the video tag.
                    </video>
                }
              </div>
            </div>
          </>
        }

      </div>}
    </div >
  )
}

export default Amenities
