import React from "react";

interface ZoomOutIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const ZoomOutIcon = ({ width = 24, height = 4, fillColor = "#191C21" }: ZoomOutIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.43995 3.44007H22.56C23.3555 3.44007 24.0002 2.79536 24.0002 1.99972C24.0002 1.20428 23.3557 0.55957 22.56 0.55957H1.43995C0.644708 0.559767 0 1.20448 0 1.99992C0 2.79536 0.644708 3.44007 1.43995 3.44007Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ZoomOutIcon;
